
import { Vue, Component } from 'vue-property-decorator'
import { FileInfo, IssueDetail, IssueFeedBackDetail } from '@/types/inspection'
import { ElForm } from 'element-ui/types/form'
import UploadFile from '@/components/uploadFile/Index.vue'
import { minLength5 } from '@/utils/validate'
import AMapLoader from '@amap/amap-jsapi-loader'
import { mapKey } from '@/utils/config'

@Component({
  components: { UploadFile },
  filters: {
    imgListFormat (list: FileInfo[]) {
      const imgList = list.map(item => {
        return item.filePrefix + item.fileUrl
      })
      return imgList || []
    }
  }
})
export default class Detail extends Vue {
  private issueInfo: IssueDetail = {
    issueTypeName: '',
    issueContent: '',
    deadLine: '',
    address: '',
    rectifierName: '',
    deduction: '0',
    resourceList: [],
    videoList: []
  }

  private issueConfigStatus = false
  private feedbackList: Array<IssueFeedBackDetail> = []
  private urgeJson: Array<IssueFeedBackDetail>= []
  private isShowConfirm = false
  private confirmInfo = {
    issueStatus: ''
  }

  private confirmRules = {
    issueStatus: [
      { required: true, message: '请选择确认结果', trigger: 'change' }
    ]
  }

  private isShowRectify = false
  private rectifyInfo: { remark: string; resourceList: Array<FileInfo> } = {
    remark: '',
    resourceList: []
  }

  private rectifyRules = {
    remark: [
      { required: true, message: '请输入反馈说明', trigger: 'change' },
      { validator: minLength5, message: '请至少输入5个字', trigger: 'change' }
    ]
  }

  // 整改审核
  private isShowAudit = false
  private pickerOptions = {
    disabledDate: (date: Date) => {
      return true
    }
  }

  private auditInfo = {
    remark: '',
    result: '',
    deadLine: ''
  }

  private auditRules = {
    result: [
      { required: true, message: '请选择审核结果', trigger: 'change' }
    ]
  }

  // 转办
  private isShowComplaint = false
  private complaintInfo = {
    transferName: '',
    transferRemark: ''
  }

  private transferNameList = []

  private complaintRules = {
    transferName: [
      { required: true, message: '请选择人员', trigger: 'change' }
    ]
  }

  private disabledBtn = false
  private map: AMap.Map | null = null

  get issueId () {
    return this.$route.params.issueId as string || ''
  }

  $refs!: {
    confirmForm: ElForm;
    rectifyForm: ElForm;
    auditForm: ElForm;
    complaintForm: ElForm;
  }

  created (): void {
    this.getDetail()
    this.getIssueConfig()
  }

  destroyed () {
    this.map && this.map.destroy()
  }

  getDetail () {
    this.$axios.get(this.$apis.inspection.selectIssue, {
      issueId: this.issueId
    }).then(res => {
      this.issueInfo = res.details
      this.urgeJson = res.urgeJson
      this.feedbackList = res.feedbackList
      if (this.issueInfo.longitude && this.issueInfo.latitude) {
        this.loadMap()
      }
      this.auditInfo.deadLine = this.issueInfo.deadLine
      this.pickerOptions.disabledDate = (date: Date) => {
        return date.getTime() < new Date(this.issueInfo.deadLine).getTime()
      }
    })
  }

  loadMap () {
    AMapLoader.load({
      key: mapKey,
      version: '2.0'
    }).then(() => {
      this.$nextTick(() => {
        const map = new AMap.Map('container', {
          zoom: 14,
          center: [this.issueInfo.longitude, this.issueInfo.latitude]
        })
        this.map = map
        const marker: any = new AMap.Marker({
          icon: new AMap.Icon({
            // 图标的取图地址
            image: require('@/assets/icon/dispatch/icon_map_shijian.svg')
          }),
          offset: new AMap.Pixel(-18, -48),
          position: new AMap.LngLat(Number(this.issueInfo.longitude), Number(this.issueInfo.latitude))
        })
        this.map && this.map.add(marker)
      })
    }).catch(e => {
      this.$message({ type: 'error', message: '地图加载失败：' + JSON.stringify(e) })
    })
  }

  // 查询巡查问题节点配置和待确认问题总数 issueConfigStatus 开关状态 0关闭 1开启
  getIssueConfig () {
    this.$axios.get(this.$apis.inspection.selectIssueConfig).then(res => {
      this.issueConfigStatus = res.issueConfigStatus
    })
  }

  submitIssueConfirm () {
    this.$refs.confirmForm.validate(valid => {
      if (valid) {
        this.issueConfirm()
      }
    })
  }

  // 问题确认
  issueConfirm () {
    this.disabledBtn = true
    this.$axios.post(this.$apis.inspection.updateConfirmStatus, {
      issueId: this.issueId,
      ...this.confirmInfo
    }).then(() => {
      this.$message.success('确认成功')
      this.$router.back()
    }).finally(() => {
      this.disabledBtn = false
    })
  }

  // 关闭确认弹框
  closeConfirm () {
    this.confirmInfo.issueStatus = ''
    this.$refs.confirmForm.clearValidate()
    this.isShowConfirm = false
  }

  // 文件上传成功返回
  onSuccess (file: FileInfo) {
    this.rectifyInfo.resourceList.push(file)
  }

  imgRemove (index: number) {
    this.rectifyInfo.resourceList.splice(index, 1)
  }

  submitIssueRectify () {
    this.$refs.rectifyForm.validate(valid => {
      if (valid) {
        this.issueRectify()
      }
    })
  }

  // 问题反馈
  issueRectify () {
    this.disabledBtn = true
    this.$axios.post(this.$apis.inspection.rectify, {
      issueId: this.issueId,
      ...this.rectifyInfo
    }).then(() => {
      this.$message.success('反馈成功')
      this.$router.back()
    }).finally(() => {
      this.disabledBtn = false
    })
  }

  // 关闭反馈弹框
  closeRectify () {
    this.rectifyInfo = {
      remark: '',
      resourceList: []
    }
    this.$refs.rectifyForm.clearValidate()
    this.isShowRectify = false
  }

  submitIssueAudit () {
    this.$refs.auditForm.validate(valid => {
      if (valid) {
        this.issueAudit()
      }
    })
  }

  // 问题审核
  issueAudit () {
    this.disabledBtn = true
    this.$axios.post(this.$apis.inspection.audit, {
      issueId: this.issueId,
      ...this.auditInfo
    }).then(() => {
      this.$message.success('审核成功')
      this.$router.back()
    }).finally(() => {
      this.disabledBtn = false
    })
  }

  // 关闭审核弹框
  closeAudit () {
    this.auditInfo = {
      remark: '',
      result: '',
      deadLine: ''
    }
    this.$refs.auditForm.clearValidate()
    this.isShowAudit = false
  }

  // 转办
  getRectifierList () {
    this.$axios.get(this.$apis.inspection.selectUserByProcessNodeCodeByList, {
      processNodeCode: '1',
      processCode: '1', // 写死，代表巡查
      objectId: this.issueInfo.patrolId
    }).then(res => {
      this.transferNameList = res.list || []
    })
  }

  openComplaint () {
    this.isShowComplaint = true
    this.getRectifierList()
  }

  submitComplaint () {
    this.$refs.complaintForm.validate(valid => {
      if (valid) {
        this.updateRectifierIssue()
      }
    })
  }

  updateRectifierIssue () {
    this.disabledBtn = true
    this.$axios.post(this.$apis.inspection.updateRectifierIssue, {
      issueId: this.issueId,
      ...this.complaintInfo
    }).then(() => {
      this.$message.success('转办成功')
      this.$router.back()
    }).finally(() => {
      this.disabledBtn = false
    })
  }

  // 关闭转办弹框
  closeComplaint () {
    this.complaintInfo = {
      transferName: '',
      transferRemark: ''
    }
    this.$refs.complaintForm.clearValidate()
    this.isShowComplaint = false
  }
}
